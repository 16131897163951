import { createSlice } from "@reduxjs/toolkit";
import { isEqual, parse } from "helpers";

const initialState = {
  contentRequests: [],
  loaded: false,
  form: {},
  defaultSite: {
    contentRequests: [],
    loaded: false,
  },
};

const contentRequestsSlice = createSlice({
  name: "contentRequests",
  initialState,
  reducers: {
    getAllTemplateContentRequests: (state, action) => {
      state.contentRequests = action.payload;
      state.loaded = true;
    },
    addTemplateContentRequest: (state, action) => {
      state.contentRequests.unshift(action.payload);
      state.form = {};
    },
    updateTemplateContentRequest: (state, action) => {
      const index = state.contentRequests.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        for (let key in action.payload) {
          state.contentRequests[index][key] = action.payload[key];
        }
      }
      state.form = {};
    },
    deleteTemplateContentRequest: (state, action) => {
      state.contentRequests = state.contentRequests.filter(
        (_) => !isEqual(_.id, action.payload)
      );
      state.form = {};
    },
    updateFormState: (state, action) => {
      state.form = action.payload;
    },
    getAllDefaultContentRequest: (state, action) => {
      state.defaultSite.contentRequests = action.payload;
      state.defaultSite.loaded = true;
    },
    addDefaultContentRequest: (state, action) => {
      const index = state.contentRequests.findIndex((_) =>
        isEqual(_.id, action.payload.id)
      );
      if (index !== -1) {
        state.contentRequests[index].default_tem = 1;
        state.defaultSite.contentRequests.unshift(state.contentRequests[index]);
      }
    },
    deleteDefaultContentRequest: (state, action) => {
      const index = state.contentRequests.findIndex((_) =>
        isEqual(_.id, action.payload)
      );
      if (index !== -1) {
        state.contentRequests[index].default_tem = 0;
      }
      state.defaultSite.contentRequests =
        state.defaultSite.contentRequests.filter(
          (_) => !isEqual(_.id, action.payload)
        );
    },
    updateContentRequestItemInContentRequest: (state, action) => {
      state.contentRequests = state.contentRequests.map((ele) => {
        let parsedContentItems = JSON.parse(ele.contentitems);
        parsedContentItems = parsedContentItems.map((item) => {
          if (isEqual(item.id, action.payload.id)) {
            return action.payload;
          }
          return item;
        });
        ele.contentitems = JSON.stringify(parsedContentItems);
        return ele;
      });
      state.form = {
        ...state.form,
        contentitems: parse(state?.form?.contentitems ?? "[]")?.map((item) => {
          if (isEqual(item.id, action.payload.id)) {
            return action.payload;
          }
        }),
      };
    },
  },
});

export const {
  getAllTemplateContentRequests,
  addTemplateContentRequest,
  updateTemplateContentRequest,
  deleteTemplateContentRequest,
  updateFormState,
  getAllDefaultContentRequest,
  addDefaultContentRequest,
  deleteDefaultContentRequest,
  updateContentRequestItemInContentRequest,
} = contentRequestsSlice.actions;

export default contentRequestsSlice.reducer;
