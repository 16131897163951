import { useEffect, useState } from "react";
import { compact, isEqual, useSearch } from "helpers";
import "./index.css";
import { SiteSettingsMenu } from "enums";
import { useUserPermissions } from "hooks/user";
import { useLocation, useNavigate } from "react-router-dom";
import { featureMenu, getGeneralMenu, userPrefrencesMenu } from "staticData";

export default function SecondaryMenu({isLibraryTemplateAllowed,...props}) {
  const [activeMenu, setActiveMenu] = useState(
    props.activeMenu ?? SiteSettingsMenu.GENERAL_SETTINGS
  );
  const {pathname} = useLocation()
  const navigate = useNavigate();
  const {
    add_items_from_template_library,
  } = useUserPermissions();
  const { web } = useSearch();
  const menuItems = [
    {
      title: "General Settings",
      key: SiteSettingsMenu.GENERAL_SETTINGS,
      onClick: () => navigate(web ? `/site-settings/general-settings${web ? `?web=${web}`: ''}` : "/site-settings/users")
    },
    {
      title: "Feature Settings",
      key: SiteSettingsMenu.FEATURES_SETTINGS,
      onClick: () => navigate(`/site-settings/launchpad-widgets${web ? `?web=${web}`: ''}`)
    },
    !web && isLibraryTemplateAllowed && {
      title: "User Preferences",
      key: SiteSettingsMenu.USER_PREFRENCES,
      onClick: () => navigate(`/site-settings/notifications${web ? `?web=${web}`: ''}`)
    },
    !web && isLibraryTemplateAllowed && add_items_from_template_library && {
      title: "Library Templates",
      key: SiteSettingsMenu.LIBRARY_TEMPLATES,
      onClick: () => navigate(`/site-settings/library-item/tasks${web ? `?web=${web}`: ''}`)
    },
  ];

  useEffect(() => {
    const module = pathname.split('/')[2];
    const isGeneralSetting = getGeneralMenu(true).find(ele => isEqual(ele.module, module))
    if (isGeneralSetting) {
      handleClick(SiteSettingsMenu.GENERAL_SETTINGS) 
      return;
    }

    const isFeatureSetting = featureMenu.find(ele => isEqual(ele.module, module))
    if (isFeatureSetting) {
      handleClick(SiteSettingsMenu.FEATURES_SETTINGS) 
      return;
    }

    const isUserPreferences = userPrefrencesMenu.find(ele => isEqual(ele.module, module))
    if (isUserPreferences) {
      handleClick(SiteSettingsMenu.USER_PREFRENCES) 
      return;
    }

  }, [])

  function handleClick(value) {
    props.setActiveMenu(value);
    setActiveMenu(value);
  }

  return (
    <div className="main-menu-list">
      {compact(menuItems).map((item) => (
        <div
          className={`main-menu-list-tab ${
            activeMenu === item.key ? "active-menu" : ""
          }`}
          onClick={() => {handleClick(item.key); item.onClick()}}
        >
          <h2>{item.title}</h2>
        </div>
      ))}
    </div>
  );
}
