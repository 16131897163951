import { useSites } from "api/sites";
import {
  BodyContainer,
  DeleteButton,
  HeaderContainer,
  Loader,
  PrimaryHeading,
  RightMainContainer,
} from "commonUI";
import { isEqual, useSearch } from "helpers";
import useDeleteSite from "hooks/advanced";

export default function SiteSetting(props) {
  const { mutateAsync: deleteSite, isLoading: deletingSite } = useDeleteSite();
  const { web = null } = useSearch();
  const { data } = useSites();
  const siteDetails = data?.site_details?.total_sites?.find((site) =>
    isEqual(site.dl_website_id, web)
  );

  return (
    <>
      <Loader show={deletingSite} />
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Advanced</PrimaryHeading>
        </HeaderContainer>
        <BodyContainer>
          <PrimaryHeading className="text-17">Site Data</PrimaryHeading>
          {/* <SecondaryHeading className="mt-2">
            Override Site Settings With The New Site Default Configuration
            Settings
          </SecondaryHeading>
          <p className="body-text text-theme-placeholder text-11 mt-1">
            Choose to reset all the local plugin settings and replace them with
            the default site configuration settings.
          </p>
          <MainButton>
            <ResetIcon size="12" /> Reset Site Settings
          </MainButton>
          <SecondaryHeading className="mt-2">
            Import & Export Site Data
          </SecondaryHeading>
          <p className="body-text mt-1">
            The WP SiteLauncher site data can be exported and imported to act as
            a backup and restore method. Please be careful when importing data,
            as it will override the exiting site data.
          </p>
          <div className="flex gap-7 mt-2 mb-2">
            <MainButton>
              <ExportIcon /> Export Site Data
            </MainButton>
            <MainButton>
              <ImportIcon /> Import Site Data
            </MainButton>
          </div>
          <SecondaryHeading className="mt-2">Delete Site Data</SecondaryHeading>
          <p className="body-text mt-1">
            If you are no longer using WP SiteLauncher on this site, you can
            choose to delete the data in order to free up storage space in your
            account.. You can choose to export the site data first as a backup
            for peace of mind.
          </p>
          <MainButton className="delete-button-red">
            <DeleteIcon fill="white" />
            Delete Site Data
          </MainButton> */}
          <div className="mt-3">
            {/* <SecondaryHeading className="mt-2">Delete Site</SecondaryHeading>
            <p className="body-text mt-1">
              If you no longer need this site, you can choose to delete it from
              your account. This will permanently remove the site and all of its
              associated data.
            </p>
            <MainButton
              className="delete-button-red"
              disabled={isLoading}
              onClick={() => {
                deleteSite({
                  web_url: siteDetails.web_url,
                  website_id: siteDetails.dl_website_id,
                });
              }}
            >
              <DeleteIcon fill="white" />
              Delete Site
            </MainButton> */}
            <DeleteButton
              type="button"
              buttonText="Delete Site"
              title="Delete Site"
              message="If you no longer need this site, you can choose to delete it from your account. This will permanently remove the site and all of its associated data. NOTE: This will not affect the actual WordPress site."
              deleteMessage="You are about to delete the site. This action cannot be undone. Are you sure you want to delete the site?"
              deleteButtonText="Confirm Delete"
              onDelete={async() => {
                deleteSite({
                  web_url: siteDetails.web_url,
                  website_id: siteDetails.dl_website_id,
                });
              }}
              style={{
                left: '0',
                right: 'auto',
              }}
            />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
