import { useState } from "react";
import { BackArrow, SaveIcon } from "allIcons";
import {
  AddComponent,
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  MainButton,
  DropDownCard,
  Loader,
  PopOver,
  DeleteButton,
} from "commonUI";
import { useParams, useNavigate } from "react-router-dom";
import { updateTask, useTasks } from "api/templateLibrary/tasks";
import { useTags } from "api/siteSettings/Tags";
import { useMutation } from "@tanstack/react-query";
import { addTask, deleteTask } from "api/templateLibrary/tasks";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleTask,
  deleteSingleTask,
  updateSingleTask,
} from "redux/reducers/templateLibrary/tasks";
import { useNotification } from "notification";
import { useEffect } from "react";
import { useUserPermissions, useUserRole } from "hooks/user";
import { isEqual } from "helpers";

export default function AddEditTask() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { userData } = useSelector((state) => state.user);
  const { data: tags, isLoading: tagsLoading, error: tagsError } = useTags();
  const { data: tasks, isLoading } = useTasks();
  const { add_new_templates, edit_templates, delete_templates } = useUserPermissions();
  const { isSuperAdmin } = useUserRole();

  const [inputValues, setInputValues] = useState({
    title: "",
    message: "",
    tags: [],
  });
  const [openTags, setOpenTags] = useState(false);
  useEffect(() => {
    if (
      (!add_new_templates && id === "add") ||
      (!edit_templates && id !== "add")
    ) {
      return navigate("/template-library/tasks");
    }

    if (isLoading) return;
    if (tasks) {
      if (id === "add") return;
      let task = tasks.filter((task) => Number(task.id) === Number(id))[0];
      if (!task) {
        notification({
          type: "error",
          message: "Task Not Found",
        });
        return navigate("/template-library/tasks");
      }
      setInputValues({
        ...task,
        title: task.title,
        description: task.message ?? task.description,
        tags:
          typeof task.tags != "object"
            ? Array.isArray(JSON.parse(task.tags))
              ? JSON.parse(task.tags ?? "[]")
              : [JSON.parse(task.tags ?? "[]")]
            : task.tags ?? [],
      });
    } else {
      if (id === "add") return;
      let task = tasks.filter((task) => Number(task.id) === Number(id))[0];
      if (!task) {
        notification({
          type: "error",
          message: "Task Not Found",
        });
        return navigate("/template-library/tasks");
      }
      setInputValues({
        ...task,
        title: task.title,
        message: task.message ?? task.description,
        tags:
          typeof task.tags != "object"
            ? JSON.parse(task.tags || "[]")
            : task.tags ?? [],
      });
    }
  }, [isLoading]);

  const addMutation = useMutation(addTask, {
    onSuccess: (data) => {
      navigate(`/template-library/tasks/${data.Data.id}`);
      dispatch(addSingleTask(data.Data));
      notification({
        type: "success",
        message: "Task Added Successfully",
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const updateMutation = useMutation(updateTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Updated Successfully",
      });
      dispatch(updateSingleTask(data.Data));
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteMutation = useMutation(deleteTask, {
    onSuccess: (data) => {
      notification({
        type: "success",
        message: "Task Deleted Successfully",
      });
      dispatch(deleteSingleTask(data.id));
      navigate("/template-library/tasks");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function handleSave() {
    if (inputValues.title === "" || inputValues.message === "") {
      notification({
        type: "error",
        message: `Please ${
          inputValues.title === ""
            ? "Enter Title"
            : inputValues.message === ""
            ? "Enter Description"
            : "Enter all fields"
        }`,
      });
      return;
    }
    if (id === "add") {
      addMutation.mutate({
        ...inputValues,
        name: userData.name,
        requested_from: [
          {
            user_avatar:
              "http://0.gravatar.com/avatar/c2b06ae950033b392998ada50767b50e?s=96&#038;d=mm&#038;r=g",
            name: userData.name,
            role: "Client",
            group: "Admin",
            id: userData.id,
          },
        ],
      });
    } else {
      updateMutation.mutate({ ...inputValues, id });
    }
  }
  return (
    <>
      <RightMainContainer>
        {addMutation.isLoading ||
        updateMutation.isLoading ||
        deleteMutation.isLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/template-library/tasks")} />
            <PrimaryHeading className="clamp-text">
              {id === "add" ? "Add Task" : inputValues.title || ""}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && (!isEqual(inputValues.system_status, 1) || isSuperAdmin) && delete_templates}
              onDelete={() => deleteMutation.mutate(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            <MainButton onClick={handleSave}>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={inputValues.title}
            setValue={(value) =>
              setInputValues({ ...inputValues, title: value })
            }
          />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            value={inputValues.message}
            reRender={
              id === "add" ? false : inputValues.message ? true : false
            }
            setValue={(value) =>
              setInputValues({ ...inputValues, message: value })
            }
            blur={openTags}
          />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {(inputValues?.tags ?? []).map((tag, index) => (
                <PreviewCard
                  color={
                    (tag.color?.[0] ?? "") == "#"
                      ? tag.color
                      : "#" + tag.color ?? ""
                  }
                  title={tag.text || tag.tag_title}
                  icon={true}
                  className="m-0"
                  key={index}
                  onCross={() => {
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter((item) => item.id !== tag.id),
                    }));
                  }}
                />
              ))}
            </div>
          </div>
          <AddComponent onClick={() => setOpenTags(true)}>
            <PopOver
              open={openTags}
              onClose={() => setOpenTags(false)}
              className="absolute bottom-full mb-2"
              style={{
                width: "270px",
                top: "auto",
                right: "auto",
                bottom: "calc(100% + 10px)",
              }}
            >
              <DropDownCard
                title="Tags"
                listStyle={true}
                className="relative-important"
                selectedValues={inputValues.tags.map((item) => item.id)}
                list={tags.map((ele) => ({
                  ...ele,
                  index: ele.id,
                  text: ele.tag_title,
                }))}
                onSelect={(item) => {
                  if (
                    inputValues.tags.find(
                      (i) => Number(i.id) === Number(item.id)
                    )
                  ) {
                    return;
                  }
                  setInputValues((pre) => ({
                    ...pre,
                    tags: [
                      ...pre.tags,
                      {
                        id: item.id,
                        text: item.tag_title,
                        color: item.color,
                      },
                    ],
                  }));
                }}
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
