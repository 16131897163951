import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomeScreen from "main/HomeScreen";
import ForgotPassword from "userAuth/ForgotPassword";
import Login from "userAuth/Login";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import { useUserAuth } from "context/UserAuth";
import CreatePassword from "userAuth/CreatePassword";
import ResetPassword from "userAuth/ResetPassword";

export default function Main() {
  const {
    userAuth: { token },
  } = useUserAuth();

  return (
    <>
  <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={token ? <Navigate to="/site" /> : <Login />}
        />
        <Route
          path="/signup"
          element={<Navigate to="/login" replace />}
        />
        <Route
          path="/create-password"
          element={token ? <Navigate to="/" /> : <CreatePassword />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password"
          element={token ? <Navigate to="/" /> : <ResetPassword />}
        />
        <Route
          path="/*"
          element={token ? <HomeScreen /> : <Navigate to="/login" />}
        />
      </Routes>
    </BrowserRouter>
    </>
  );
}
